import React, { useContext } from "react"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import { Flex, Text, Box } from "theme-ui"

import { formatVariantPrice } from "../../utils/prices"
import QuantityPicker from "../new-ui/quantity-picker"
import Select, { DetailTypes, Option } from "../new-ui/select"
import { InterfaceContext } from "../../context/InterfaceContext"
import NotifyBackInStock from "../new-ui/modal/notify-back-in-stock"
import { sortVariants } from "../../utils/functions/product-util"
import CloseIcon from "../../assets/svg/dismiss.svg"

const Container = styled(Box)`
  display: grid;
  & > * {
    margin-bottom: 1rem;
  }

  & > *:last-of-type {
    margin-bottom: 0;
  }

  margin-top: 1rem;
`

const ModalContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.white};
  min-width: 200px;
  padding: 20px;

  .icon {
    cursor: pointer;
    width: 20px;
  }
`

const BeddingVariantPicker = ({
  multiSelection,
  bundle,
  cart,
  onQuantityChanged,
  onVariantClick,
  inventory,
}) => {
  const { t } = useTranslation()
  const { setModalContent, setModalVisible } = useContext(InterfaceContext)
  const { duvetProduct, pillowProduct, flatSheetProduct } = bundle

  const isSleep = duvetProduct.sku.startsWith("SW")

  const duvetVariant =
    duvetProduct && duvetProduct.variants.find((v) => !!multiSelection[v.id])

  const pillowVariant =
    pillowProduct && pillowProduct.variants.find((v) => !!multiSelection[v.id])

  const flatSheetVariant =
    flatSheetProduct &&
    flatSheetProduct.variants.find((v) => !!multiSelection[v.id])

  const createOptions = (variants, disableNotifyMe) => {
    return variants.map((next) => {
      const quantity = inventory[next.objectId]
      var optionType = DetailTypes.PRICE
      var optionText = ""
      var optionAction = null

      if (!quantity || quantity === 0) {
        optionType =
          disableNotifyMe === true ? DetailTypes.SOLD_OUT : DetailTypes.ACTION
        optionText =
          disableNotifyMe === true ? t("sold_out") : t("notify_when_back")
        optionAction = () => {
          if (disableNotifyMe === true) {
            return
          }
          setModalContent(
            <ModalContainer>
              <Flex sx={{ justifyContent: "flex-end" }}>
                <CloseIcon
                  onClick={() => setModalVisible(false)}
                  className="icon"
                />
              </Flex>
              <NotifyBackInStock
                product={next}
                label={`${bundle.name || ""} ${next.title || ""} ${
                  next.type || ""
                }`}
              />
            </ModalContainer>
          )
          setModalVisible(true)
        }
      } else if (quantity && quantity < 5) {
        optionType = DetailTypes.TEXT
        optionText = t("x_in_stock", { x: quantity })
      }

      const result = {
        label: next.type ? `${next.title} ${next.type}` : next.title,
        title: next.title,
        type: next.type,
        key: next.title,
        action: optionAction,
        details: {
          type: optionType,
          text: optionText,
          value: formatVariantPrice(cart, next),
        },
        ...next,
      }

      return result
    })
  }

  const productRecommendation = (product) => {
    const allOptions = createOptions(
      sortVariants({
        variants: product.variants,
        sku: product.variants[0].sku,
      }),
      product.disableNotifyMe
    )

    const recommended = allOptions.reduce((acc, next) => {
      if (next.regions?.some((region) => region.name === cart.region.name)) {
        acc.push(next)
      }
      return acc
    }, [])

    return function createRecommendations() {
      return (
        <Flex sx={{ flexDirection: "column" }}>
          {recommended.length > 0 && (
            <>
              <Text
                m="16px"
                mb="0"
                sx={{
                  color: "brownGrey",
                  fontSize: "12px",
                  width: "190px",
                }}
              >
                {t("product_location_recommendation")}
              </Text>
              {recommended.map((option) => (
                <Option
                  key={`option-${option.label}`}
                  option={option}
                  onClick={() => onVariantClick(option, product)}
                  className="option"
                />
              ))}

              <Text
                m="16px"
                mt="24px"
                mb="0"
                sx={{
                  color: "brownGrey",
                  fontSize: "12px",
                }}
              >
                {t("all_sizes")}
              </Text>
            </>
          )}
          {allOptions.map((option) => (
            <Option
              key={`option-${option.label}`}
              option={option}
              onClick={() => onVariantClick(option, product)}
              className="option"
            />
          ))}
        </Flex>
      )
    }
  }

  return (
    <Container>
      {duvetProduct && (
        <Flex mb={"8px"}>
          <Select
            value={duvetVariant && { label: duvetVariant?.title }}
            valueRender={() => (
              <Text>
                {t("selected_type", { type: t(isSleep ? "shirt" : "duvet") })}:{" "}
                <strong>{duvetVariant?.title}</strong>
              </Text>
            )}
            valuePlaceholder={t("select_type_size", {
              type: t(isSleep ? "shirt" : "duvet"),
            })}
            onOptionClick={(option) => {
              onVariantClick(option, duvetProduct)
            }}
            options={
              duvetProduct.variants &&
              createOptions(
                sortVariants({
                  variants: duvetProduct.variants,
                  sku: duvetProduct.variants[0].sku,
                }),
                duvetProduct.disableNotifyMe
              )
            }
            allOptionsRender={productRecommendation(duvetProduct)}
          />
          <QuantityPicker
            quantity={
              !!duvetVariant &&
              !!multiSelection[duvetVariant.id] &&
              multiSelection[duvetVariant.id].quantity
            }
            onQuantityChanged={(q) => onQuantityChanged(q, duvetVariant)}
            visible={duvetVariant !== undefined}
            sx={{ border: "dark", borderLeft: "none", height: "initial" }}
          />
        </Flex>
      )}
      {pillowProduct && (
        <Flex mb={"8px"}>
          <Select
            value={pillowVariant && { label: pillowVariant?.title }}
            valueRender={() => (
              <Text>
                {t("selected_type", {
                  type: t(isSleep ? "pants" : "pillow"),
                })}
                : <strong>{pillowVariant?.title}</strong>
              </Text>
            )}
            valuePlaceholder={t("select_type_size", {
              type: t(isSleep ? "pants" : "pillow"),
            })}
            onOptionClick={(option) => {
              onVariantClick(option, pillowProduct)
            }}
            options={
              pillowProduct.variants &&
              createOptions(
                sortVariants({
                  variants: pillowProduct.variants,
                  sku: pillowProduct.variants[0].sku,
                }),
                pillowProduct.disableNotifyMe
              )
            }
            allOptionsRender={productRecommendation(pillowProduct)}
          />
          <QuantityPicker
            quantity={
              !!pillowVariant &&
              !!multiSelection[pillowVariant.id] &&
              multiSelection[pillowVariant.id].quantity
            }
            onQuantityChanged={(q) => onQuantityChanged(q, pillowVariant)}
            visible={pillowVariant !== undefined}
            sx={{ border: "dark", borderLeft: "none", height: "initial" }}
          />
        </Flex>
      )}
      {flatSheetProduct && (
        <Flex mb={"8px"}>
          <Select
            value={flatSheetVariant && { label: flatSheetVariant?.title }}
            valueRender={() => (
              <Text>
                {t("selected_type", {
                  type: t(isSleep ? "shorts" : "sheet"),
                })}
                : <strong>{flatSheetVariant?.title}</strong>
              </Text>
            )}
            valuePlaceholder={t("select_type_size", {
              type: t(isSleep ? "shorts" : "sheet"),
            })}
            onOptionClick={(option) => {
              onVariantClick(option, flatSheetProduct)
            }}
            options={
              flatSheetProduct.variants &&
              createOptions(
                sortVariants({
                  variants: flatSheetProduct.variants,
                  sku: flatSheetProduct.variants[0].sku,
                }),
                flatSheetProduct.disableNotifyMe
              )
            }
            allOptionsRender={productRecommendation(flatSheetProduct)}
          />
          <QuantityPicker
            quantity={
              !!flatSheetVariant &&
              !!multiSelection[flatSheetVariant.id] &&
              multiSelection[flatSheetVariant.id].quantity
            }
            onQuantityChanged={(q) => onQuantityChanged(q, flatSheetVariant)}
            visible={flatSheetVariant !== undefined}
            sx={{ border: "dark", borderLeft: "none", height: "initial" }}
          />
        </Flex>
      )}
    </Container>
  )
}

export default BeddingVariantPicker
