import React, { useContext } from "react"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import { Flex, Text, Box } from "theme-ui"

import { formatVariantPrice } from "../../utils/prices"
import { InterfaceContext } from "../../context/InterfaceContext"
import NotifyBackInStock from "../new-ui/modal/notify-back-in-stock"
import Button from "../new-ui/button"
import { DetailTypes } from "../new-ui/select"
import { sortVariants } from "../../utils/functions/product-util"
import CloseIcon from "../../assets/svg/dismiss.svg"

const VariantPickerContainer = styled(Box)`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;

  > button {
    margin-bottom: 8px;
  }

  > button:not(button:last-of-type) {
    margin-right: 8px;
  }
`

const ModalContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.white};
  min-width: 200px;
  padding: 20px;

  .icon {
    cursor: pointer;
    width: 20px;
  }
`

const VariantPicker = ({
  product,
  cart,
  inventory,
  selectedVariant,
  onVariantClick,
  multiSelection,
}) => {
  const { variants, disableNotifyMe } = product
  const { t } = useTranslation()
  const { setModalContent, setModalVisible } = useContext(InterfaceContext)

  const getQuantity = (objectId) => inventory[objectId]
  const sku = variants[0].sku

  const createOptions = (variants) => {
    return variants.map((next) => {
      const quantity = inventory[next.objectId]
      var optionType = DetailTypes.PRICE
      var optionText = ""
      var optionAction = null

      if (!quantity || quantity === 0) {
        optionType =
          disableNotifyMe === true ? DetailTypes.SOLD_OUT : DetailTypes.ACTION
        optionText =
          disableNotifyMe === true ? t("sold_out") : t("notify_when_back")
        optionAction = () => {
          if (disableNotifyMe === true) {
            return
          }
          setModalContent(
            <ModalContainer>
              <Flex sx={{ justifyContent: "flex-end" }}>
                <CloseIcon
                  onClick={() => setModalVisible(false)}
                  className="icon"
                />
              </Flex>
              <NotifyBackInStock
                product={next}
                label={`${product.color.name || ""} ${next.type || ""}`}
              />
            </ModalContainer>
          )
          setModalVisible(true)
        }
      } else if (quantity && quantity < 5) {
        optionType = DetailTypes.TEXT
        optionText = t("x_in_stock", { x: quantity })
      }

      const result = {
        label: next.type || next.title,
        title: next.title,
        type: next.type,
        key: next.title,
        action: optionAction,
        details: {
          type: optionType,
          text: optionText,
          value: formatVariantPrice(cart, next),
        },
        ...next,
      }

      return result
    })
  }
  const StyledText = ({ children, ...rest }) => (
    <Text {...rest}>{children}</Text>
  )

  const StyledButton = ({ children, ...rest }) => (
    <Button p="8xp 16px" {...rest}>
      {children}
    </Button>
  )

  return (
    <VariantPickerContainer>
      {createOptions(sortVariants({ variants: variants, sku: sku })).map(
        (next) => {
          let quantity = getQuantity(next.objectId)
          let type = next.details.type
          const renderDetail = () => {
            if (type === DetailTypes.ACTION || type === DetailTypes.SOLD_OUT) {
              return (
                <StyledText pr="6px" sx={{ fontWeight: "bold" }}>
                  {next.details.text}
                </StyledText>
              )
            }

            if (quantity < 5) {
              return (
                <StyledText pr="6px" sx={{ fontWeight: "bold" }}>
                  {t("amount_left", { count: quantity })}
                </StyledText>
              )
            }

            return null
          }

          const buttonProps = {}
          const typeProps = {}
          if (type === DetailTypes.ACTION || type === DetailTypes.SOLD_OUT) {
            typeProps.textDecoration = "line-through"
            buttonProps.borderColor = "brownGrey"
          }

          return (
            <StyledButton
              key={next.id}
              sx={buttonProps}
              onClick={() => {
                if (type === DetailTypes.SOLD_OUT) {
                  return
                }
                if (type === DetailTypes.ACTION) {
                  next.action()
                } else {
                  if (multiSelection?.[next.id]?.quantity === quantity) {
                    return
                  }
                  onVariantClick(next, product)
                }
              }}
              variant={selectedVariant.id === next.id ? "primary" : "base"}
            >
              <Flex>
                <StyledText pr={"5px"} sx={typeProps}>
                  {next.label}
                </StyledText>
                {renderDetail()}
                <StyledText>{next.details.value}</StyledText>
                {multiSelection?.[next.id]?.quantity && (
                  <StyledText pl={"5px"}>
                    (&#8201;{multiSelection?.[next.id]?.quantity}&#8201;)
                  </StyledText>
                )}
              </Flex>
            </StyledButton>
          )
        }
      )}
    </VariantPickerContainer>
  )
}

export default VariantPicker
