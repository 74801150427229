import Medusa from "../../services/api"

export const getEstimatedShippingTime = async productIds => {
  if (typeof window !== "undefined") {
    const raw = localStorage.getItem("medusa::cache")
    const cart = JSON.parse(raw)
    if (cart) {
      return Medusa.shippingOptions
        .list({
          product_ids: productIds,
          region_id: cart.region_id,
        })
        .then(({ data }) => {
          const { shipping_options } = data
          if (shipping_options.length) {
            const estimated = shipping_options.find(
              o => !o.is_return && o.metadata && o.metadata.transit_time
            )

            return {
              shippingOptions: shipping_options,
              estimatedShippingTime:
                estimated && estimated.metadata.transit_time,
            }
          }
        })
    }
  }
}
