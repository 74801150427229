import React from "react"
import { Flex, Text } from "theme-ui"
import { LoginForm } from "../../account/pages/sign-in"
import { useTranslation } from "react-i18next"

const LoginModal = ({ afterSubmit }) => {
  const { t } = useTranslation()
  return (
    <Flex sx={{ maxWidth: "375px", p: 1, flexDirection: "column" }}>
      <Text sx={{ fontSize: "21px", marginY: "16px", paddingRight: "20%" }}>
        {t("sign_in_wishlist_text")}
      </Text>
      <LoginForm showSignup={true} afterSubmit={afterSubmit} />
    </Flex>
  )
}

export default LoginModal
