import React, { useContext, useState } from "react"
import { Flex, Text } from "theme-ui"
import { useTranslation, Trans } from "react-i18next"

import Button from "../button"
import InputField from "../input-field"
import Medusa from "../../../services/api"
import { trackRestockSignup } from "../../../services/analytics"
import StoreContext from "../../../context/StoreContext"

const NotifyBackInStock = ({ product, label }) => {
  const [email, setEmail] = useState("")
  const [signedUp, setSignedUp] = useState(false)
  const { objectId } = product

  const { cart } = useContext(StoreContext)
  const { t } = useTranslation()

  const submit = () => {
    Medusa.restock
      .signUp(objectId, email)
      .then(() => {
        trackRestockSignup(cart, product, email)
        setSignedUp(true)
      })
      .catch((err) => console.error(err))
  }

  return (
    <Flex
      p="24px 12px"
      sx={{
        color: "dark",
        flexDirection: "column",
        width: ["100%", "375px"],
      }}
    >
      {signedUp ? (
        <>
          <Text sx={{ fontSize: "21px", textAlign: "center" }} m="16px">
            {t("notify_request_received")}
          </Text>
          <Text sx={{ fontSize: "13px", textAlign: "center" }} my="16px">
            <Trans i18nKey="notify_request_description">
              We'll send you an email once <strong>{{ product: label }}</strong>
              , is back in stock.
            </Trans>
          </Text>
        </>
      ) : (
        <>
          <Text sx={{ fontSize: "21px" }} mb="16px">
            {t("x_sold_out", { name: label })}
          </Text>
          <Text sx={{ fontSize: "13px" }} mb="24px">
            {t("back_in_stock_email")}
          </Text>

          <InputField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={"Enter your email"}
            mb="8px"
          />
          <Button onClick={submit} sx={{ fontWeight: "bold" }}>
            {t("notify_when_back")}
          </Button>
        </>
      )}
    </Flex>
  )
}

export default NotifyBackInStock
