import React, { useCallback, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { isEmpty } from "lodash"
import { useTranslation, Trans } from "react-i18next"
import { Text, Flex, Box } from "theme-ui"
import { useNotificationContext } from "../../context/NotificationContext"
import useDrawerHook from "../../hooks/useDrawerHook"
import {
  trackAddToCart,
  trackAddToWishlist,
  trackProductViewed,
  trackSizeSelected,
} from "../../services/analytics"
import Medusa from "../../services/api"
import {
  formatMoneyAmount,
  getVariantPrice,
  getMinimumPriceOption,
} from "../../utils/prices"
import { getEstimatedShippingTime } from "../../utils/medusa"
import { ButtonDisabledOnClick } from "../new-ui/button"
import Accordion from "../new-ui/accordion"
import QuantityPicker from "../new-ui/quantity-picker"
import ColorPicker from "../ui/ColorPicker"
import LoadingSpinner from "../ui/LoadingSpinner"
import ProductPageLayout from "../ui/ProductPageLayout"
import RichText from "../new-ui/rich-text"
import BeddingVariantPicker from "./BeddingVariantPicker"
import SizeGuide from "./SizeGuide"
import VariantPicker from "./VariantPicker"
import { useStoreContext } from "../../context/StoreContext"
import Tooltip, { TooltipPlacement } from "../new-ui/tooltip"
import HeartIcon from "../../assets/svg/heart.svg"
import { useAccountContext } from "../../context/AccountContext"
import LoginModal from "../new-ui/modal/login-modal"

const LoadContainer = styled.div`
  position: absolute;
  top: -${(props) => props.theme.navbar.height};
  left: 0;
  width: 100%;
  height: 100vh;
`

const ProductPage = (props) => {
  const { pushNotification } = useNotificationContext()
  const { cart, addShippingMethod, addVariantToCart } = useStoreContext()
  const { t } = useTranslation()
  const { loggedIn, addToWishList, metadata } = useAccountContext()

  const [state, setState] = useState({
    selectedVariant: {},
    selectedVariants: {},
    estimatedShippingTime: "",
    shippingOptions: [],
    selectedGiftCardOption: {},
    inventory: {},
  })

  const {
    colorOptions,
    selectedColor,
    isBedding,
    isSleep,
    collection,
    information,
    disableNotifyMe,
  } = props

  const { collectionPath, isStandalone = false } = props.pageContext
  const [hoverColor, setHoverColor] = useState(null)
  const [gender, setGender] = useState("female")
  const [loading, setLoading] = useState(true)
  const [images, setImages] = useState()
  const [hoverImage, setHoverImage] = useState()
  const [wishListIdentical, setWishlistIdentical] = useState(false)

  const { selectedVariants, selectedVariant, selectedBundle } = state

  const selectedVariantQuantity =
    selectedVariant.id && state.selectedVariants[selectedVariant.id].quantity

  const {
    drawerContent: sizeGuideDrawer,
    drawerVisible: sizeGuideDrawerVisible,
    setDrawerVisible: setSizeGuideDrawerVisible,
  } = useDrawerHook({
    initialComponent: (
      <SizeGuide
        sizeGuideGroups={collection.sizeGuideGroups}
        initialGender={gender}
        initialSizing="cm"
      />
    ),
  })

  const {
    drawerContent: loginModal,
    setDrawerContent: setLoginModalContent,
    setDrawerVisible: setLoginModalVisible,
  } = useDrawerHook({
    initialComponent: (
      <LoginModal onSignIn={() => setLoginModalVisible(false)} />
    ),
    config: { asModal: true },
  })

  useEffect(() => {
    const pg = localStorage.getItem("tekla::pg")
    let prefersGender
    if (pg) {
      prefersGender = pg === "m" ? "male" : "female"
    }
    setGender(prefersGender || "female")
  }, [])

  useEffect(() => {
    const wishlistComparison = () => {
      const wishlist = metadata?.wishlist
      let result = true
      if (!Array.isArray(wishlist) || wishlist.length === 0) {
        return false
      }
      if (isEmpty(state.selectedVariants)) {
        return false
      }

      Object.values(state.selectedVariants).forEach((item) => {
        let exist = wishlist.some(
          (wish) => wish.variant_id === item.variant.objectId
        )

        if (exist === false) {
          result = false
        }
      })

      return result
    }

    let allItemsAdded = wishlistComparison()

    setWishlistIdentical(allItemsAdded)
  }, [metadata.wishlist, state])

  useEffect(() => {
    const load = async () => {
      // Use correct product to track view
      let product = selectedColor
      if (!product) return
      if (isBedding) {
        product = selectedColor?.bundle.duvetProduct

        const bundle = selectedColor.bundle
        if (bundle.pillowProduct) {
          trackProductViewed(bundle.pillowProduct, collection)
        }

        if (bundle.flatSheetProduct) {
          trackProductViewed(bundle.flatSheetProduct, collection)
        }
      } else {
        trackProductViewed(product, collection)
      }

      // Get estimated times
      const estimatedTimes = await getEstimatedShippingTime([
        selectedColor?.objectId,
      ])

      // Get inventory qunatities as these cannot be kept in contentful
      const inventory = await fetchInventoryQuantities()

      let packIndex = 0
      if (selectedColor?.imagePacks?.length > 1) {
        packIndex = selectedColor.imagePacks.findIndex((p) => {
          if (p.isFemale !== null) {
            return (gender === "female") === p.isFemale
          } else {
            return true
          }
        })
      }

      const tmpSelectedImagePack = selectedColor?.imagePacks?.[packIndex]

      // Create assets or images
      const assets = tmpSelectedImagePack?.assets
        ? createAssets(tmpSelectedImagePack)
        : createTmpImages(tmpSelectedImagePack)
      setImages(assets)

      setState((prev) => ({
        ...prev,
        inventory: inventory,
        shippingOptions: estimatedTimes?.shippingOptions,
        estimatedShippingTime: estimatedTimes?.estimatedShippingTime,
      }))
      setLoading(false)
    }

    load()
  }, [
    createAssets,
    createTmpImages,
    fetchInventoryQuantities,
    gender,
    collection,
    isBedding,
    selectedColor,
  ])

  const createAssets = useCallback(
    (imagepack) => {
      const { isFemale } = imagepack
      return imagepack.assets?.reduce((acc, next) => {
        var result = {
          gatsbyImageData: next.asset?.gatsbyImageData,
          hasDarkBackground: next.hasDarkBackground,
        }

        if (next.imageText || isFemale !== null) {
          result = {
            ...result,
            textRender: function createAssets() {
              return (
                <Flex
                  sx={{
                    flexDirection: "column",
                    color: next.hasDarkBackground && "white",
                    maxWidth: "140px",
                  }}
                >
                  {isFemale !== null && (
                    <Text
                      onClick={handleChangeImagePack}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {t(gender === "female" ? "view_male" : "view_female")}
                    </Text>
                  )}
                  {next.imageText && (
                    <Text
                      sx={{
                        color: "dark_50",
                        marginTop: "12px",
                        fontSize: "12px",
                      }}
                    >
                      {next.imageText}
                    </Text>
                  )}
                </Flex>
              )
            },
          }
        }

        acc.push(result)
        return acc
      }, [])
    },
    [gender, handleChangeImagePack, t]
  )

  const createTmpImages = useCallback(
    (imagepack) => {
      const { isFemale, modelHeight, modelSize } = imagepack
      return imagepack.images.reduce((acc, next, index) => {
        var result
        if (index === 0) {
          result = {
            gatsbyImageData: next.gatsbyImageData,
            textRender: function createImage() {
              return (
                <Flex sx={{ flexDirection: "column", maxWidth: "140px" }}>
                  {isFemale !== null && (
                    <Text
                      onClick={handleChangeImagePack}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {t(gender === "female" ? "view_male" : "view_female")}
                    </Text>
                  )}
                  {modelHeight && modelSize && (
                    <Text
                      sx={{
                        color: "dark_50",
                        marginTop: "12px",
                        fontSize: "12px",
                      }}
                    >
                      <Trans i18nKey="model_size_wearing">
                        Model is {{ height: modelHeight }} and is wearing a size
                        <strong>{{ size: modelSize }}</strong>
                      </Trans>
                    </Text>
                  )}
                </Flex>
              )
            },
          }
        } else {
          result = {
            gatsbyImageData: next.gatsbyImageData,
          }
        }

        acc.push(result)

        return acc
      }, [])
    },
    [gender, handleChangeImagePack, t]
  )

  const handleToggleSizeGuide = () => {
    setSizeGuideDrawerVisible(!sizeGuideDrawerVisible)
  }

  const fetchInventoryQuantities = useCallback(async () => {
    const toWait = []
    let inventory = {}
    if (isBedding) {
      if (!isEmpty(selectedColor.bundle.duvetProduct)) {
        toWait.push(
          Medusa.products
            .retrieve(selectedColor.bundle.duvetProduct.objectId)
            .then(({ data }) => {
              data.product.variants.map(
                (v) => (inventory[v.id] = v.inventory_quantity)
              )
            })
        )
      }
      if (!isEmpty(selectedColor.bundle.pillowProduct)) {
        toWait.push(
          Medusa.products
            .retrieve(selectedColor.bundle.pillowProduct.objectId)
            .then(({ data }) => {
              data.product.variants.map(
                (v) => (inventory[v.id] = v.inventory_quantity)
              )
            })
        )
      }
      if (!isEmpty(selectedColor.bundle.flatSheetProduct)) {
        toWait.push(
          Medusa.products
            .retrieve(selectedColor.bundle.flatSheetProduct.objectId)
            .then(({ data }) => {
              data.product.variants.map(
                (v) => (inventory[v.id] = v.inventory_quantity)
              )
            })
        )
      }
    } else {
      toWait.push(
        Medusa.products.retrieve(selectedColor.objectId).then(({ data }) => {
          data.product.variants.map(
            (v) => (inventory[v.id] = v.inventory_quantity)
          )
        })
      )
    }

    return Promise.all(toWait).then(() => inventory)
  }, [isBedding, selectedColor])

  // For multi-size selections selectedVariants contains the variant id
  // as object key and the quantity as its value.
  const handleVariantMultiselect = (variant, product) => {
    trackSizeSelected(product, variant, props.collection)
    const currentSelection = state.selectedVariants[variant.id]
    let quantity = 1

    if (currentSelection) {
      if (state.selectedVariant && state.selectedVariant.id !== variant.id) {
        quantity = currentSelection.quantity
      } else {
        quantity += currentSelection.quantity
      }
    }
    setState({
      ...state,
      error: false,
      selectedVariant: variant,
      selectedVariants: {
        ...state.selectedVariants,
        [variant.id]: {
          variant,
          product,
          quantity,
        },
      },
    })
  }

  const handleVariantTypeSelection = (variant, product) => {
    const newSelections = state.selectedVariants
    product.variants.forEach(({ id }) => {
      delete newSelections[id]
    })

    newSelections[variant.id] = {
      variant,
      product,
      quantity: 1,
    }

    setState({
      ...state,
      selectedVariant: {},
      selectedVariants: newSelections,
    })
  }

  const handleQuantity = (quantity, v = undefined) => {
    const variant = v || state.selectedVariant

    if (variant.id) {
      if (!quantity) {
        const newSelections = state.selectedVariants
        delete newSelections[variant.id]
        setState({
          ...state,
          selectedVariant: {},
          selectedVariants: newSelections,
        })
      } else {
        if (state.inventory[variant.objectId] >= quantity) {
          setState({
            ...state,
            error: false,
            selectedVariants: {
              ...state.selectedVariants,
              [variant.id]: {
                ...state.selectedVariants[variant.id],
                quantity,
              },
            },
          })
        }
      }
    }
  }

  const handleAddToWishlist = () => {
    const { selectedVariants } = state
    if (isEmpty(selectedVariants)) {
      pushNotification({
        id: "no-selection",
        body: t("please_select_product_variant"),
        dismiss: {
          duration: 3000,
        },
      })
      return
    }

    if (!loggedIn) {
      setLoginModalContent(
        <LoginModal
          afterSubmit={(response) => {
            addVariantsToWishlist(selectedVariants, {
              userId: response?.customer?.id,
            })
            setLoginModalVisible(false)
          }}
        />
      )
      setLoginModalVisible(true)
    } else {
      addVariantsToWishlist(selectedVariants)
    }
  }

  const addVariantsToWishlist = async (variants, config) => {
    Object.values(variants).reduce(async (current, next) => {
      await current
      return addToWishList({
        variantId: next.variant.objectId,
        metadata: {
          collection_slug: collection.slug,
          product_handle: selectedColor.handle,
        },
        config: {
          userId: config?.userId,
        },
      })
        .then((res) => {
          const wishlist = res.customer.metadata.wishlist
          const addedItem = wishlist[wishlist.length - 1]
          const customer = res.customer

          pushNotification({
            id: "wishlist-added",
            body: t("item_added_to_wishlist", {
              item: `${addedItem.title} - ${addedItem.description}`,
            }),
            dismiss: {
              duration: 5000,
            },
          })

          trackAddToWishlist({
            product: next.product,
            variant: next.variant,
            cart: cart,
            userData: { id: customer.id, email: customer.email },
          })
        })
        .catch(() =>
          pushNotification({
            id: "wishlist-rejected",
            body: t("item_already_added", {
              item: next.variant.label,
            }),
            dismiss: {
              duration: 5000,
            },
          })
        )
    }, Promise.resolve())
  }

  const handleAddToCart = () => {
    if (isEmpty(state.selectedVariant) && isEmpty(state.selectedVariants)) {
      setState({ ...state, error: true })
    }

    const { selectedVariants } = state

    let toAdd = []
    if (!isEmpty(selectedVariants)) {
      Object.keys(selectedVariants).forEach((k) => {
        toAdd.push({
          product: selectedVariants[k].product,
          productId: selectedVariants[k].product.objectId,
          variantId: selectedVariants[k].variant.objectId,
          quantity: selectedVariants[k].quantity,
          variant: selectedVariants[k].variant,
        })
      })
    }

    let summary = []
    let total = 0
    toAdd
      .filter((ca) => ca.quantity !== 0)
      .reduce((p, item) => {
        trackAddToCart(
          cart,
          item.product,
          item.variant,
          item.quantity,
          props.collection
        )
        return p.then(() => {
          return addVariantToCart(item).then(() => {
            if (item.product.isGiftcard) {
              const { selectedGiftCardOption } = state
              if (selectedGiftCardOption && selectedGiftCardOption.id) {
                addShippingMethod(selectedGiftCardOption)
              }
              summary.push(t("gc_added_to_cart"))
            } else {
              summary.push(
                t("item_added_to_cart", {
                  count: item.quantity,
                  title: item.variant.title,
                })
              )
            }
            total += item.quantity
          })
        })
      }, Promise.resolve())
      .then(() => {
        if (summary.length) {
          pushNotification({
            id: "add-to-cart",
            body: summary.join("\n\n"),
            desktopBody: (
              <Flex
                sx={{
                  flexDirection: "column",
                  color: "dark",
                  fontSize: "13px",
                }}
              >
                <Box mb="4px">
                  <Text
                    sx={{
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    {t("added_to_cart")}
                  </Text>
                </Box>
                {summary.map((n) => (
                  <Box key={n} mb="3px">
                    {n}
                  </Box>
                ))}
              </Flex>
            ),
            mobileBody: (
              <div>
                {t("x_amount_added_to_cart", {
                  count: total,
                })}
              </div>
            ),
            dismiss: {
              duration: 3000,
            },
          })
        } else {
          pushNotification({
            id: "add-to-cart",
            body: t("select_size_qty_prompt"),
            dismiss: {
              duration: 3000,
            },
          })
        }
      })
  }

  // In the color picker the color selection is a link
  // so this is only used for analytics
  const handleColorSelected = (colorOption) => {
    navigate(`${collectionPath}/${colorOption.handle}`)
  }

  // If we are dealing with single selections we calculate based on
  // the selectedVariant's price times the quantity. When dealing with multi
  // select we go through selectedVariants and sum up the prices.
  const formatSelectionPrice = () => {
    let total = 0
    const { selectedVariants } = state

    if (cart.loading) return ""

    if (!isEmpty(selectedVariants)) {
      Object.keys(selectedVariants).forEach((key) => {
        total +=
          getVariantPrice(cart, selectedVariants[key].variant) *
          selectedVariants[key].quantity
      })
    }

    if (!total) {
      if (props.isBedding) {
        if (props.isSleep) {
          total = getMinimumPriceOption(
            props.selectedColor.bundle.flatSheetProduct,
            cart
          )
        } else {
          total = getMinimumPriceOption(
            props.selectedColor.bundle.pillowProduct,
            cart
          )
        }
      } else {
        total = getMinimumPriceOption(props.selectedColor, cart)
      }

      if (!props.isSamePrice || props.isSleep) {
        return t("from_amount", {
          amount: formatMoneyAmount(
            {
              currencyCode: cart.currencyCode,
              amount: total,
            },
            0
          ),
        })
      }
    }

    return formatMoneyAmount(
      {
        currencyCode: cart.currencyCode,
        amount: total,
      },
      0
    )
  }

  const handleHoverColor = (o) => {
    if (!o) {
      setHoverColor(null)
      setHoverImage(null)
    } else {
      if (o?.imagePacks.length > 1) {
        var packIndex = o.imagePacks.findIndex((p) => {
          if (p.isFemale !== null) {
            return (gender === "female") === p.isFemale
          } else {
            return true
          }
        })
      } else {
        packIndex = 0
      }

      setHoverColor(o)
      setHoverImage(o.imagePacks[packIndex].images[0])
    }
  }

  const handleChangeImagePack = useCallback(() => {
    if (gender === "male") {
      localStorage.setItem("tekla::pg", "f")

      if (window && window.analytics) {
        window.analytics.identify({
          gender: "female",
        })
      }
    } else {
      localStorage.setItem("tekla::pg", "m")

      if (window && window.analytics) {
        window.analytics.identify({
          gender: "male",
        })
      }
    }

    setGender(gender === "male" ? "female" : "male")
  }, [gender])

  let descriptionHtml = collection.description.childMarkdownRemark.html
  if (selectedColor?.description?.childMarkdownRemark?.html) {
    descriptionHtml = selectedColor.description.childMarkdownRemark.html
  }

  return (
    <>
      <ProductPageLayout
        images={images}
        hasHoverColor={!!hoverColor}
        hoverImage={hoverImage}
      >
        <ProductPageLayout.Content lowMargin>
          <Flex sx={{ justifyContent: "space-between" }} mt={["20px", "0px"]}>
            <h1>{collection.name}</h1>
            <Text
              sx={{
                fontSize: "13px",
              }}
            >
              {formatSelectionPrice()}
            </Text>
          </Flex>

          {!isStandalone && collection.teaser && (
            <ProductPageLayout.Teaser
              dangerouslySetInnerHTML={{
                __html: collection.teaser.childMarkdownRemark.html,
              }}
            />
          )}
          {isStandalone && descriptionHtml && (
            <ProductPageLayout.Teaser
              dangerouslySetInnerHTML={{
                __html: descriptionHtml,
              }}
            />
          )}
          {selectedColor?.tooltip && (
            <Tooltip
              text={selectedColor.tooltip.label}
              popover={selectedColor.tooltip.text}
              options={{
                iconFirst: true,
                withIcon: true,
                placement:
                  selectedColor.tooltip.placement || TooltipPlacement.BOTTOM,
              }}
              sx={{
                fontSize: "11px",
                fontWeight: "bold",
                marginY: "16px",
              }}
            />
          )}
          {colorOptions?.length > 1 && !isStandalone && (
            <Flex
              mb="8px"
              sx={{
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box className="hr" />
              <ColorPicker
                oneline={isSleep}
                onColorSelected={handleColorSelected}
                onColorHover={handleHoverColor}
                selectedOption={hoverColor || selectedColor}
                options={colorOptions}
              />
            </Flex>
          )}
          {collection.sizeGuideGroups && !!collection.sizeGuideGroups.length && (
            <>
              <Box className="hr" mt="20px" />
              <Flex sx={{ justifyContent: "space-between", fontSize: "13px" }}>
                <Text>{t("size")}</Text>
                <Text
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handleToggleSizeGuide}
                >
                  {t("view_size_guide")}
                </Text>
              </Flex>
            </>
          )}
          {loading ? (
            <LoadContainer>
              <LoadingSpinner />
            </LoadContainer>
          ) : (
            <>
              {isBedding === true && (
                <BeddingVariantPicker
                  multiSelection={selectedVariants}
                  error={state.error}
                  cart={cart}
                  onQuantityChanged={handleQuantity}
                  onVariantClick={handleVariantTypeSelection}
                  bundle={selectedColor.bundle}
                  selectedOption={selectedVariant}
                  inventory={state.inventory}
                />
              )}
              {isBedding === false && (
                <VariantPicker
                  error={state.error}
                  cart={cart}
                  onVariantClick={handleVariantMultiselect}
                  product={selectedColor}
                  selectedVariant={selectedVariant}
                  inventory={state.inventory}
                  collection={collection}
                  multiSelection={selectedVariants}
                  disableNotifyMe={disableNotifyMe}
                />
              )}
            </>
          )}
          {!isBedding && (
            <Flex sx={{ flexDirection: "column" }}>
              <QuantityPicker
                visible={!isEmpty(selectedVariant)}
                quantity={selectedVariantQuantity}
                onQuantityChanged={handleQuantity}
                disabled={
                  selectedVariantQuantity === undefined ||
                  selectedVariantQuantity === null
                }
                minQuantity={0}
                sx={{
                  width: "100%",
                  height: "40px",
                  border: "dark",
                  marginTop: "8px",
                }}
              />
            </Flex>
          )}

          <Box
            sx={{
              display: ["none", "initial"],
              height: "40px",
              marginTop: "8px",
              width: "100%",
            }}
          >
            <ButtonDisabledOnClick
              onClick={handleAddToCart}
              isDisabled={
                isEmpty(selectedVariant) &&
                isEmpty(selectedBundle) &&
                isEmpty(selectedVariants)
              }
              disabledOnClick={() =>
                pushNotification({
                  id: "choose-size",
                  body: t("select_size_qty_prompt"),
                  dismiss: {
                    duration: 3000,
                  },
                })
              }
              sx={{ height: "48px", fontWeight: "300", width: "100%" }}
            >
              {t("add_to_bag")}
            </ButtonDisabledOnClick>
          </Box>
          <Flex
            sx={{
              marginY: "16px",
              alignItems: "center",
              svg: {
                marginRight: "16px",
                cursor: "pointer",
              },
            }}
            onClick={handleAddToWishlist}
          >
            <HeartIcon />
            <Text>
              {wishListIdentical
                ? t("items_exist_in_wishlist")
                : t("save_to_wishlist")}
            </Text>
          </Flex>

          <Flex sx={{ flexDirection: "column" }} my={"32px"}>
            {state.estimatedShippingTime && (
              <Text sx={{ fontSize: "13px", color: "medium" }} mb={"8px"}>
                {`${t("delivery_estimate")} ${state.estimatedShippingTime}
                ${t("delivery_business_days")}`}
              </Text>
            )}

            <Accordion
              items={
                information &&
                information.map((next) => {
                  const result = {
                    name: next.title,
                    id: next.id,
                    contentRender: function createAccordion() {
                      return (
                        <RichText
                          key={next.id}
                          input={next}
                          noMargin={true}
                          noPadding={true}
                        />
                      )
                    },
                  }
                  return result
                }, [])
              }
            />
          </Flex>
        </ProductPageLayout.Content>
        <Box
          sx={{
            display: ["block", "none"],
            position: "sticky",
            bottom: "0",
            width: "100%",
          }}
        >
          <ButtonDisabledOnClick
            onClick={handleAddToCart}
            isDisabled={
              isEmpty(selectedVariant) &&
              isEmpty(selectedBundle) &&
              isEmpty(selectedVariants)
            }
            disabledOnClick={() =>
              pushNotification({
                id: "choose-size",
                body: t("select_size_qty_prompt"),
                dismiss: {
                  duration: 3000,
                },
              })
            }
            sx={{ height: "48px", fontWeight: "300", width: "100%" }}
          >
            {t("add_to_bag")}
          </ButtonDisabledOnClick>
        </Box>
      </ProductPageLayout>
      {sizeGuideDrawer}
      {loginModal}
    </>
  )
}

export default ProductPage
