import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"

import Layout from "../components/layouts"
import ContentModules from "../components/Page/ContentModules"
import ProductPage from "../components/ProductPage/new-product-page"
import SEO from "../components/seo"
import { useTranslationContext } from "../context/TranslationContext"

const Container = styled.div`
  .collection-info + .content-module {
    border-top: 1px solid ${(props) => props.theme.colors.darkest};
  }
`

const getColorOptions = (products) => {
  // For bedding bundles we use the duvet product as reference
  return products.map((p) => {
    let isBedding = p.internal.type === "ContentfulBeddingBundle"
    if (isBedding) {
      let { duvetProduct, pillowProduct, flatSheetProduct } = p
      let bundleProducts = [
        duvetProduct,
        pillowProduct,
        flatSheetProduct,
      ].filter((bp) => bp !== null)

      // We want both duvet, pillow and flat sheets to map to this option
      // therefore we decorate with the slugs field.
      return {
        ...duvetProduct,
        slugs: bundleProducts.map((bp) => bp.handle),
        bundle: p,
      }
    }

    // We need the slug field here as well for normalization.
    return {
      ...p,
      slugs: [p.handle],
    }
  })
}

const ProductPageTemplate = ({ data, pageContext }) => {
  const {
    contentfulCollection: collection,
    contentfulCollection: {
      name,
      seoTitle,
      seoDescription,
      description,
      contentModules,
    },
  } = data
  const canonicalUrl = pageContext.canonicalUrl

  const { translationContext } = useTranslationContext()

  const [colorOptions, setColorOptions] = useState()
  const [selectedColor, setSelectedColor] = useState()
  const [state, setState] = useState()

  useEffect(() => {
    const colors = getColorOptions(collection.products)
    let selectedSlug = pageContext.productSlug

    if (!selectedSlug) {
      selectedSlug = colors?.[0].handle
    }

    const selected = colors.find((o) => o.slugs.includes(selectedSlug))

    setColorOptions(colors)
    setSelectedColor(selected)

    const isBedding = selected.bundle !== undefined
    const isSleep =
      isBedding && selected.bundle.duvetProduct.sku.startsWith("SW")

    const colorDetails = selected.color

    let jsonLdProduct = isBedding ? selected.bundle.duvetProduct : selected

    const prices = jsonLdProduct.variants.map(
      (v) =>
        v.prices.find((p) => p.currency_code.toLowerCase() === "dkk").amount
    )

    const highPrice = Math.max(...prices)
    const lowPrice = Math.min(...prices)
    jsonLdProduct.highPrice = highPrice
    jsonLdProduct.lowPrice = lowPrice

    setState((state) => ({
      ...state,
      jsonLdProduct: jsonLdProduct,
      isBedding: isBedding,
      isSleep: isSleep,
      colorDetails: colorDetails,
    }))
  }, [collection.products, pageContext.productSlug])

  const handleContentModules = () => {
    switch (true) {
      case Boolean(selectedColor?.bundle?.duvetProduct?.contentModules):
        return (
          <div className="content-module">
            <ContentModules
              allModules={
                selectedColor?.bundle?.duvetProduct?.contentModules || []
              }
            />
          </div>
        )

      case Boolean(selectedColor?.contentModules):
        return (
          <div className="content-module">
            <ContentModules allModules={selectedColor?.contentModules || []} />
          </div>
        )
      case Boolean(contentModules):
        return (
          <div className="content-module">
            <ContentModules allModules={contentModules || []} />
          </div>
        )
    }
  }

  return (
    <Layout pullUp>
      <Container>
        <SEO
          canonicalUrl={canonicalUrl}
          jsonLdProduct={state?.jsonLdProduct}
          canonicals={translationContext.canonicals}
          title={seoTitle || `${name} – ${state?.colorDetails.name}`}
          description={
            seoDescription?.seoDescription || description.description
          }
        />
        <ProductPage
          selectedColor={selectedColor}
          isBedding={state?.isBedding}
          isSleep={state?.isSleep}
          colorOptions={colorOptions}
          collection={collection}
          information={collection.information}
          pageContext={pageContext}
        />
        {selectedColor && handleContentModules()}
      </Container>
    </Layout>
  )
}

export default ProductPageTemplate

export const query = graphql`
  query($slug: String!, $locale: String!) {
    contentfulCollection(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      information {
        ... on ContentfulRichText {
          id
          title
          body {
            raw
          }
          internal {
            type
          }
        }
      }
      ...CollectionContent
    }
  }
`
