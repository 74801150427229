import { ContentfulTypes } from "../types"

// A product has a gender if it has more than 2 image packs
export const hasGender = (products) => {
  return !!products.find((p) => isProductWithGender(p))
}

export const isProductWithGender = (product) => {
  return product.internal.type === "ContentfulBeddingBundle"
    ? product.duvetProduct?.imagePacks?.length > 1
    : product.imagePacks && product.imagePacks.length > 1
}

const handleBundle = (product, isFemale) => {
  let packIndex = 0

  if (product.duvetProduct.imagePacks.length !== 1) {
    packIndex = product.duvetProduct.imagePacks.findIndex(
      (i) => !!i.isFemale === isFemale
    )
  }

  return {
    id: product.id,
    title: product.name,
    image: product.duvetProduct.imagePacks[packIndex].featuredImage,
    alternateImage: product.duvetProduct.imagePacks[packIndex].alternateImage,
    handle: product.duvetProduct.handle,
  }
}

const handleProduct = (product, isFemale) => {
  let packIndex = 0

  if (product.imagePacks.length !== 1) {
    packIndex = product.imagePacks.findIndex((i) => !!i.isFemale === isFemale)
  }

  return {
    id: product.id,
    name: product.title,
    title: product.color.name,
    image: product.imagePacks[packIndex].featuredImage,
    alternateImage: product.imagePacks[packIndex].alternateImage,
    handle: product.handle,
  }
}

export const getImagesFromProducts = (products, gender) => {
  let images = products.map((product) => {
    if (product.internal.type === ContentfulTypes.ContentfulBeddingBundle) {
      return handleBundle(product, gender === "female")
    }

    if (product.internal.type === ContentfulTypes.ContentfulProduct) {
      return handleProduct(product, gender === "female")
    }
  })

  if (images === undefined || images[0] === undefined) {
    return []
  }

  return images
}

export const getImagesFromCollectionBundle = (bundle, gender) => {
  let images = bundle.map((collection) => {
    return {
      products: getImagesFromProducts(collection.products, gender),
      collectionPath: collection.collectionPath,
    }
  })

  if (images === undefined || images[0] === undefined) {
    return []
  }
  return images
}

const score = (a) => {
  switch (a) {
    case "XXtra Small":
    case "X-Xtra Small":
      return 0
    case "Xtra Small":
    case "Extra Small":
    case "Extra Small":
      return 1
    case "Small":
      return 2
    case "Medium":
      return 3
    case "Large":
      return 4
    case "Xtra Large":
    case "Extra Large":
      return 5
    default:
      return 6
  }
}

export const sortVariants = ({ variants, sku }) => {
  let sorted = []
  if (
    sku &&
    (sku.startsWith("BT") || sku.startsWith("S") || sku.startsWith("CB"))
  ) {
    const sortingAlgo = (a, b) => {
      const as = score(a.title)
      const bs = score(b.title)

      return as - bs
    }

    sorted = variants.sort(sortingAlgo)
  } else {
    const cur = "dkk"
    const sortingAlgo = (a, b) => {
      const aP = a.prices.find((ma) => ma.currency_code === cur)
      const bP = b.prices.find((ma) => ma.currency_code === cur)

      if (bP.amount && aP.amount) {
        return aP.amount - bP.amount
      }

      return a.title.localeCompare(b.title)
    }
    sorted = variants.sort(sortingAlgo)
  }

  return sorted
}
