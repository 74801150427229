import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import Typography from "../ui/Typography"
import { ImageCarousel } from "../new-ui/image"

const ProductPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    flex-direction: row;
  }
`

const ProductPicker = styled.div`
  padding-top: 10px;
  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding-top: ${(props) => props.theme.navbar.height};
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scroll-behavior: smooth;

  background-color: ${(props) => props.theme.colors.almostWhite};
`

const ProductPageLayout = ({ images, hasHoverColor, children, hoverImage }) => (
  <ProductPageContainer>
    <ImageCarousel
      images={images}
      hasHoverColor={hasHoverColor}
      hoverImage={hoverImage}
      withZoom={true}
      sx={{ height: ["80%", "auto"] }}
    />
    <ProductPicker>{children}</ProductPicker>
  </ProductPageContainer>
)

/**
 * Small muted text
 */
ProductPageLayout.Teaser = styled.div`
  font-size: 13px;
  p {
    padding: 0;
    margin-bottom: 0.2rem;
  }
`

ProductPageLayout.Price = styled.div`
  ${Typography.Medium}
  display: flex;
  align-items: baseline;
`

ProductPageLayout.Content = styled.div`
  color: ${(props) => props.theme.colors.dark};
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 19px;
    font-weight: 400;
  }

  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;

  width: 100%;

  .hr {
    background: ${(props) => props.theme.colors.dark_10};
    margin-bottom: 5px;
    height: 1px;
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    overflow: scroll;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  ${(props) => props.theme.breakpointsLegacy.hd} {
    max-width: 640px;
    padding-top: 80px;
    padding-left: 120px;
    padding-right: 80px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    padding-top: 1.5rem;
    margin-bottom: 20px;

    ${(props) => props.theme.breakpointsLegacy.tablet} {
      flex-wrap: unset;
    }
  }

  .info-container {
    padding: 2rem;

    &.bottom {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .selection-container {
    padding: 2rem;
    background-color: white;
    box-shadow: 0px 0px 2px ${(props) => props.theme.colors.light};
  }
`

/**
 * Large text
 */
ProductPageLayout.CollectionHeader = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin-bottom: 7px;

  h1 {
    ${Typography.Large};
    margin-bottom: 0;
  }
`

/**
 * List of terms.
 */
ProductPageLayout.Terms = styled.div`
  ${Typography.Small};
  font-weight: bold;

  display: flex;
  flex-direction: column-reverse;

  flex-grow: 1;
  color: ${(props) => props.theme.colors.dark};
  > div {
    margin-bottom: 0.5rem;
  }

  padding-bottom: 0.5rem;

  .holiday-terms {
    font-weight: normal;
  }
`

export const SizeGuideContainer = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.almostWhite};
  z-index: 180000;
`

ProductPageLayout.Link = styled.div`
  ${Typography.Medium};
  cursor: pointer;
`

ProductPageLayout.propTypes = {
  images: PropTypes.array,
  hasHoverColor: PropTypes.bool,
  children: PropTypes.node,
}

export default ProductPageLayout
