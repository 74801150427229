import React from "react"
import styled from "@emotion/styled"
import isTouchDevice from "is-touch-device"

const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  width: 100%;
  padding-top: 8px;
`

const ColorOptionContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, 24px);

  ${(props) => props.theme.breakpointsLegacy.mobile} {
    grid-template-columns: repeat(
      ${(props) => props.colLength || "auto-fit"},
      24px
    );
  }

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    grid-template-columns: repeat(auto-fit, 24px);
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    grid-template-columns: repeat(10, 24px);
  }

  grid-column-gap: 12px;
  grid-row-gap: 12px;

  padding-bottom: 20px;
`

const ColorOption = styled.span`
  cursor: pointer;
  &::after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => props.backgroundImage});
    background-color: ${(props) => props.color};
  }
  border-radius: 50%;
  height: 24px;
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.darkest : theme.colors.brownGrey};
`

const Text = styled.div`
  font-size: 13px;
  font-weight: 400;
  padding-left: 10px;
  width: 90px;
  text-align: right;
  color: ${(props) => props.theme.colors.dark_70};
`

const ColorPicker = ({
  margin,
  options,
  selectedOption,
  oneline,
  onColorSelected,
  onColorHover,
}) => {
  const isSelected = (option) =>
    selectedOption && option.color.id === selectedOption.color.id

  const handleMouseExit = () => {
    if (isTouchDevice()) return false
    if (onColorHover) {
      onColorHover()
    }
  }

  const handleMouseEnter = (o) => {
    if (isTouchDevice()) return false
    if (onColorHover) {
      onColorHover(o)
    }
  }

  let colLength
  if (!oneline && options.length > 8) {
    colLength = parseInt(options.length / 2) + (options.length % 2)
  }

  return (
    <ColorPickerContainer margin={margin}>
      <ColorOptionContainer colLength={colLength}>
        {options.map((o) => (
          <ColorOption
            key={o.color.id}
            data-testid={o.color.id}
            data-ga-category="Color Picker"
            data-ga-label={o.color.name}
            onClick={() => onColorSelected && onColorSelected(o)}
            onMouseEnter={() => handleMouseEnter(o)}
            onMouseLeave={() => handleMouseExit()}
            selected={isSelected(o)}
            backgroundImage={
              o.color.customIndicator && o.color.customIndicator.file.url
            }
            color={o.color.colorCode}
          />
        ))}
      </ColorOptionContainer>
      <Text>{selectedOption && selectedOption.color.name}</Text>
    </ColorPickerContainer>
  )
}

export default ColorPicker
